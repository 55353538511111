import request from '@/libs/request'


/**
 * 注册
 */
export function addRegisterListApi(data) {
  return request({
    url: '/franchise/register',
    method: 'post',
    data
  })
}

/**
 * 获取验证码
 */
export function getVerifyListApi() {
  return request({
    url: '/franchise/verify',
    method: 'get',
  })
}

/**
 * 登录
 */
export function getLoginListApi(data) {
  return request({
    url: '/franchise/login',
    method: 'post',
    data
  })
}

/**
 * 获取验证码
 */
export function getVerificationCodeApi(phone, scene) {
  return request({
    url: `/franchise/send?phone=${phone}&scene=${scene}`,
    method: 'get',
  })
}

/**
 * 手机注册或登录
 */
export function phoneLoginApi(data) {
  return request({
    url: '/user/userlogin',
    method: 'post',
    data
  })
}

/**
 * 提交特许经营资质申请
 */
export function getFranchiseInfoApi() {
  return request({
    url: '/franchise/dataEdit',
    method: 'get',
  })
}

/**
 * 提交特许经营资质申请
 */
export function sumbitFranchiseInfoApi(data) {
  return request({
    url: '/franchise/dataAdd',
    method: 'post',
    data
  })
}

/**
 * 用户数据导入
 */
export function userAddApi(data) {
  return request({
    url: '/import/franchise/user',
    method: 'post',
    data
  })
}

/**
 * 用户数据列表
 */
export function getUserListApi(page, limit) {
  return request({
    url: `/import/franchise/user?page=${page}&limit=${limit}`,
    method: 'get'
  })
}

/**
 * 获取文化程度配置参数
 */
export function getEducationInfoAPI() {
  return request({
    url: `/map/education/info`,
    method: 'get'
  })
}
