<template>
  <div class="user-list">
    <div class="c-title">已导数据列表</div>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 用户列表 -->
      <el-table v-loading="loading" :data="userList"
        :header-cell-style="{'text-align': 'center',background: '#f2f2f2',color: '#838a96',border: 'none'}"
        :cell-style="{ 'text-align': 'center', color: '#000' }" style="width: 100%">
        <el-table-column prop="user_id" label="用户ID">
        </el-table-column>
        <el-table-column prop="user_truename" label="姓名">
        </el-table-column>
        <el-table-column prop="user_phone" label="手机号">
        </el-table-column>
        <el-table-column prop="user_idcard" label="身份证号">
        </el-table-column>
        <el-table-column prop="education_level" label="学历">
          <template slot-scope="scope">
            <span>{{scope.row.education_level|educationFilter}}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination :page.sync="page" :limit.sync="limit" :total="userCount" @getList="getUserList()"
        class="l-pagination" />
    </el-card>
  </div>
</template>

<script>
  import {
    getUserListApi
  } from '@/api/franchise'
  import Pagination from '@/components/Pagination/index'
  export default {
    name: 'userList',
    components: {
      Pagination
    },
    data() {
      return {
        userList: [],
        userCount: 0,
        loading: false,
        page: 1,
        limit: 10
      }
    },
    created() {
      this.getUserList()
    },
    filters: {
      educationFilter(val) {
        switch (val) {
          case 1:
            return '小学';
            break;
          case 2:
            return '初中';
            break;
          case 3:
            return '高中';
            break;
          case 4:
            return '大学专科';
            break;
          case 5:
            return '大学本科';
            break;
          case 6:
            return '研究生';
            break;
        }
      }
    },
    methods: {
      // 获取用户列表
      async getUserList() {
        this.loading = true
        const {
          data: res
        } = await getUserListApi(this.page, this.limit)
        if (res.status !== 10000) {
          return this.$message.error('获取用户列表数据失败')
        }
        this.userList = res.result.list
        this.userCount = res.result.count
        this.loading = false
      }
    }
  }

</script>

<style lang="less" scoped>
  .user-list {
    text-align: left;
    line-height: normal;

    .c-title {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
    }

    .l-pagination {
      text-align: right;
      margin-top: 20px;
    }
  }

</style>
